import axios from "axios";
import React, {useState} from "react";
import {Button} from "../../../ui/core";

const Billing = ({setError, setSuccess}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubscriptionManagement = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true);
        let customerPortal = null;
        axios.get('/accounts/billing').then(
            res => {
                if (res.status === 200) {
                    customerPortal = res.data.url;
                    if (customerPortal !== null && customerPortal !== '') {
                        setIsLoading(false);
                        window.location.href = customerPortal;
                    } else {
                        setError('Error getting subscription management portal')
                        setIsLoading(false);
                    }
                } else {
                    setError('Error getting subscription management portal')
                    setIsLoading(false);
                }
            },
            err => {
                setError('Error getting subscription management portal')
                setIsLoading(false);

            }
        );
    };

    return (
        <>
            <Button loading={isLoading} onClick={handleSubscriptionManagement}>Manage your subscription</Button>
        </>
    )
}

export default Billing;