import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import AddPostConfigModal from "./AddPostConfigModal.js";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import PostConfigForm from "./PostConfigForm";
import axios from "axios";
import {PostConfigContext} from "./PostConfigContext"
import {useAuth} from "../../authContext";

function AddPostConfig() {
    const auth = useAuth();
    const updatePostConfigs = useContext(PostConfigContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const postConfigObj = {
        name: "",
        description: "",
        connectorId: "",
        leagueName: "",
        hashtags: [],
    }

    const config = {
        leagueName: "",
        hashtags: [],
    }

    const data = {
        name: "",
        description: "",
        connectorId: "",
        config: config,
    }

    const [connectorId, setConnectorId] = useState('')
    const [postConfig, setPostConfig] = useState({...postConfigObj});
    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        data.name = postConfig.name;
        data.description = postConfig.description;
        data.connectorId = connectorId;
        data.config.leagueName = postConfig.leagueName;
        data.config.hashtags = postConfig.hashtags;

        await axios.post('/socialmedia/' + auth.user.tenant + '/post-configs', data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new post config", type: "success"});
                    setIsModal(false);
                    updatePostConfigs();
                } else {
                    setError("An error occurred while trying to save the post config");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the post config");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add post config
                        </Button>
                    </div>
                    <AddPostConfigModal
                        title="Add New Post Config"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <PostConfigForm
                            {...{
                                loading,
                                postConfig: postConfig,
                                setPostConfig: setPostConfig,
                                connectorId: connectorId,
                                setConnectorId: setConnectorId,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddPostConfigModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddPostConfig;
