import Page from "../ui/Page";
import BookmarksManagement from "../components/metabase/Bookmarks";
import PostsManagement from "../components/socialmedia/Posts";

function SocialMedia() {
    return (
        <Page title="Bookmarks">
            <PostsManagement/>
        </Page>
    );
}

export default SocialMedia;