import styled from "styled-components";

const Loader = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid rgb(255, 0, 0, 0.2);
  border-left-color: red;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const RemovingState = styled.span`
  color: red;
`

function Removing() {
    return (
        <>
            <Loader/>
            <RemovingState> Removing</RemovingState>
        </>
    )
}

export default Removing;