import Page from "../ui/Page";
import Dashboard from "../components/metabase/Dashboard";

function Analytics() {
    return (
        <Page title="Analytics">
            <Dashboard/>
        </Page>
    );
}

export default Analytics;