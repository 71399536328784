import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import AddConnectorModal from "./AddConnectorModal.js";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import ConnectorForm from "./ConnectorForm";
import axios from "axios";
import {ConnectorContext} from "./ConnectorContext"
import {useAuth} from "../../authContext";

function AddConnector() {
    const auth = useAuth();
    const updateConnectors = useContext(ConnectorContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const connectorObj = {
        name: "",
        description: "",
        autoSync: false,
        interval: 0,
        clientSecret: "",
        apiUrl: "",
        auth: "",
        timeZone: "",
        apiKey: "",
        appId: "",
        url: "",
        fanObjectId: "",
        username: "",
        password: "",
        apiKeySecret: "",
        accessToken: "",
        accessTokenSecret: "",
        accountName: "",
    }

    const providerConfig = {
        discriminator: "",
        clientSecret: "",
        apiUrl: "",
        auth: "",
        timeZone: "",
        apiKey: "",
        appId: "",
        url: "",
        fanObjectId: "",
        username: "",
        password: "",
        apiKeySecret: "",
        accessToken: "",
        accessTokenSecret: "",
        accountName: "",
    }

    const config = {
        autoSync: false,
        interval: 0,
    }

    const data = {
        name: "",
        description: "",
        config: config,
        providerConfig: providerConfig,
    }

    const [provider, setProvider] = useState('')
    const [connector, setConnector] = useState({...connectorObj});
    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        data.name = connector.name;
        data.description = connector.description;
        data.config.autoSync = connector.autoSync;
        data.config.interval = connector.interval;
        data.providerConfig.discriminator = provider;
        data.providerConfig.clientSecret = connector.clientSecret;
        data.providerConfig.apiUrl = connector.apiUrl;
        data.providerConfig.auth = connector.auth;
        data.providerConfig.timeZone = connector.timeZone;
        data.providerConfig.apiKey = connector.apiKey;
        data.providerConfig.appId = connector.appId;
        data.providerConfig.url = connector.url;
        data.providerConfig.fanObjectId = connector.fanObjectId;
        data.providerConfig.username = connector.username;
        data.providerConfig.password = connector.password;
        data.providerConfig.apiKeySecret = connector.apiKeySecret;
        data.providerConfig.accessToken = connector.accessToken;
        data.providerConfig.accessTokenSecret = connector.accessTokenSecret;
        data.providerConfig.accountName = connector.accountName;

        await axios.post('/integrations/' + auth.user.tenant + '/connectors', data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new connector", type: "success"});
                    setIsModal(false);
                    updateConnectors();
                } else {
                    setError("An error occurred while trying to save the connector");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the connector");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add connector
                        </Button>
                    </div>
                    <AddConnectorModal
                        title="Add new connector"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <ConnectorForm
                            {...{
                                addToast: addToast,
                                loading,
                                connector: connector,
                                setConnector: setConnector,
                                provider: provider,
                                setProvider: setProvider,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddConnectorModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddConnector;
