import styled from 'styled-components';
import Remove from "./Remove";
import Edit from "./Edit";

const CardStyle = styled.div`
  background-color: white;
  min-width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  border: var(--color-secondary) solid 1px;
`

const TitleContainer = styled.div`
  font-size: 0.8rem;
  background-color: var(--color-secondary);
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  padding: 4px;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.8rem;
  padding: 2px;
  margin-top: 10px;
`

const ActionsContainer = styled.div`
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 7px;
`

function PostConfigItem({postConfig, connectors}) {
    return (
        <CardStyle>
            <TitleContainer>{postConfig.name}</TitleContainer>
            <HeaderContainer>
                {connectors !== null &&
                    <div>
                        Connector: {connectors.get(postConfig.connectorId)}
                    </div>
                }
                <div>
                    League: {postConfig.config.leagueName}
                </div>
                <div>
                    Hastags: {postConfig.config.hashtags.join(", ")}
                </div>
                <ActionsContainer>
                    <Edit postConfig={postConfig}></Edit>
                    <Remove postConfig={postConfig}></Remove>
                </ActionsContainer>
            </HeaderContainer>
        </CardStyle>
    )
}


export default PostConfigItem;
