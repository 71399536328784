import {Route, Redirect} from 'react-router-dom';
import {useAuth} from './authContext';

function PrivateRoute({children, ...rest}) {
    const auth = useAuth();
    return (
        <Route {...rest} render={({location}) => {
            if (auth.user === null) {
                return <p>Check authorization access...</p>
            }
            if (auth.user && auth.user.billing === null) {
                console.log('Redirect to /pricing');
                return <Redirect to="/pricing"/>
            }
            return auth.user ? children : (
                <Redirect to={{
                    pathname: '/login',
                    state: {from: location}
                }}/>
            );
        }}/>
    );
}

export default PrivateRoute;