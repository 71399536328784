import {Button, Field, Message} from "../../../ui/core";

function UserForm({
                      loading, onSubmit,
                      user,
                      setUser,
                      error,
                  }) {

    const onChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Username" id="username-title">
                    <input
                        type="text"
                        value={user.username}
                        onChange={onChange}
                        name="username"
                        id="username-title"
                    />
                </Field>
                <Field labelText="First Name" id="firstName-title">
                    <input
                        type="text"
                        value={user.firstName}
                        onChange={onChange}
                        name="firstName"
                        id="firstName-title"
                    />
                </Field>
                <Field labelText="Last Name" id="lastName-title">
                    <input
                        type="text"
                        value={user.lastName}
                        onChange={onChange}
                        name="lastName"
                        id="lastName-title"
                    />
                </Field>
                <Field labelText="Email" id="email-title">
                    <input
                        type="email"
                        value={user.email}
                        onChange={onChange}
                        name="email"
                        id="email-title"
                    />
                </Field>
                <Field labelText="Password" id="password-title">
                    <input
                        type="password"
                        value={user.password}
                        onChange={onChange}
                        name="password"
                        id="password-title"
                    />
                </Field>
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
            </form>

        </>
    );
}

export default UserForm;
