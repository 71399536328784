import Page from "../ui/Page";
import {useAuth} from "../authContext";
import React, {useEffect, useState} from "react";
import axios from "axios";
import FansTable from "../components/fans/FansTable";
import {FanContext} from "../components/fans/FanContext";
import AddFan from "../components/fans/AddFan";

function Fans() {
    const auth = useAuth();
    const [fans, setFans] = useState([]);

    const updateFans = (offset = 0, initial = false) => {
        if (initial) {
            setFans([]);
        }

        axios
            .get(auth.user.tenant + `/fans?limit=10000&offset=${offset}`)
            .then((res) => {
                const newFans = res.data.items;
                setFans((prevFans) => [...prevFans, ...newFans]);

                if (res.data.moreItems) {
                    updateFans(offset + 10000, false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        updateFans();
    }, []);

    return (
        <Page title="Fans">
            <FanContext.Provider value={updateFans}>
                <AddFan/>
                <FansTable data={fans}/>
            </FanContext.Provider>
        </Page>
    );
}

export default Fans;
