import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import styled from "styled-components";
import {AudienceContext} from "./AudienceContext.js";
import {useContext} from "react";
import {useAuth} from "../../authContext";
import {FaTrash} from "react-icons/fa";

const ActionButton = styled.button`
  & {
    background-color: red;
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 20%;
    max-width: 80px;
  }

  &:hover {
    background-color: red;
    opacity: 0.5;
  }
`


function Remove(props) {
    const auth = useAuth();
    const updateAudiences = useContext(AudienceContext);

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        await axios.delete('/marketing/' + auth.user.tenant + '/audiences/' + props.audience.id).then(
            res => {
                if (res.status === 200) {
                    addToast({text: "Starting removed successfully", type: "success"});
                    updateAudiences();
                } else {
                    addToast({text: "Error removing audience", type: "error"});
                }
            }
        ).catch(
            err => {
                addToast({text: "Error removing audience", type: "error"});
            })
    };


    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={onSubmit.bind(this, addToast)}>
                        <FaTrash/>
                    </ActionButton>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default Remove;
