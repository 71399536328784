import Page from "../ui/Page";
import AudienceItems from "../components/marketing/Audiences";
import PostConfigItems from "../components/socialmedia/PostConfigs";

function PostConfigs() {
    return (
        <Page title="Post Configs">
            <PostConfigItems/>
        </Page>
    );
}

export default PostConfigs;