import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FaTrash} from 'react-icons/fa';
import axios from "axios";
import {BookmarkContext} from "./BookmarkContext";
import {useAuth} from "../../authContext";
import {Message} from "../../ui/core";

const BookmarkItem = ({bookmark, onDelete}) => {
    return (
        <BookmarkCard>
            <BookmarkInfo>
                <strong>{bookmark.name}</strong>
                <span>{bookmark.description}</span>
                <span>Params: {bookmark.params}</span>
            </BookmarkInfo>
            <DeleteButton onClick={() => onDelete(bookmark.id)}>
                <FaTrash/>
            </DeleteButton>
        </BookmarkCard>
    );
};

const BookmarkList = ({
                          bookmarks, onDelete
                      }) => {

    const updateBookmarks = useContext(BookmarkContext);
    return (
        <Container>
            <BookmarkContext.Provider value={updateBookmarks}>
                {bookmarks.map((bookmark) => (
                    <BookmarkItem key={bookmark.id} bookmark={bookmark} onDelete={onDelete}/>
                ))}
            </BookmarkContext.Provider>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const BookmarkCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

const BookmarkInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  strong {
    font-size: 18px;
    margin-bottom: 4px;
  }

  span {
    color: #666;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #ff4d4f;

  &:hover {
    color: #d63031;
  }
`;

const BookmarksManagement = () => {
    const auth = useAuth();
    const [bookmarks, setBookmarks] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const updateBookmarks = () => {
        setError(null)
        axios
            .get('/analytics/' + auth.user.tenant + '/bookmarks')
            .then((res) => {
                setBookmarks(res.data.items);
            })
            .catch((err) => {
                setError('Error updating bookmarks')
            });
    };

    useEffect(() => {
        updateBookmarks();
    }, []);


    const handleBookmarkDelete = (bookmarkId) => {
        setSuccess(null)
        setError(null)
        axios
            .delete('/analytics/' + auth.user.tenant + '/bookmarks/' + bookmarkId)
            .then((res) => {
                if (res.status === 200) {
                    setSuccess("bookmark deleted successfully")
                    updateBookmarks();
                } else {
                    setError('error deleting bookmark')
                }

            })
            .catch((err) => {
                setError('error deleting bookmark')
            });
    };

    return (<BookmarkContext.Provider value={updateBookmarks}>
        <Message text={error} type="error"/>
        <Message text={success} type="success"/>
        <BookmarkList bookmarks={bookmarks} onDelete={handleBookmarkDelete}/>
    </BookmarkContext.Provider>)
};

export default BookmarksManagement;
