import React, {useEffect, useState} from "react";
import axios from "axios";
import {useAuth} from "../../authContext";
import {AudienceContext} from "./AudienceContext";
import styled from "styled-components";
import AudienceItem from "./AudienceItem";
import AddAudience from "./AddAudience";
import {EventSourcePolyfill} from "event-source-polyfill";
import {API_URL} from "../../constants";

const AudienceContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

let connectorMap = new Map();
let EventSource = EventSourcePolyfill;

function AudienceItems() {
    const auth = useAuth();
    const [audiences, setAudiences] = useState([]);
    const [connectors, setConnectors] = useState(null);
    const updateAudiences = () => {
        axios.get('/marketing/' + auth.user.tenant + '/audiences').then(
            res => {
                setAudiences(res.data.items)
            },
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        const eventSource = new EventSource(API_URL + '/system/sse', {
            headers: {
                'Authorization': axios.defaults.headers.common['Authorization']
            }
        });
        eventSource.onmessage = (event) => {
            const eventData = event.data;
            if (eventData === 'AudienceUpdated' || eventData === 'AudienceDeleted') {
                updateAudiences();
            }
        };
        axios.get('/integrations/' + auth.user.tenant + '/connectors?type=marketing').then(
            res => {
                res.data.items.map((connector) => {
                    connectorMap.set(connector.id, connector.name)
                })
                setConnectors(connectorMap);
            },
            err => {
                console.log(err)
            }
        )
        updateAudiences();
        return () => {
            eventSource.close();
        };
    }, [])

    return (
        <AudienceContext.Provider value={updateAudiences}>
            <AddAudience/>
            <AudienceContainer>
                {audiences.map((audience) => (
                    <AudienceItem audience={audience} connectors={connectors}/>
                ))}
            </AudienceContainer>
        </AudienceContext.Provider>
    );
}

export default AudienceItems;