import React, {useEffect, useState} from "react";
import axios from "axios";
import {useAuth} from "../../authContext";
import {ConnectorContext} from "./ConnectorContext";
import styled from "styled-components";
import ConnectorItem from "./ConnectorItem";
import AddConnector from "./AddConnector";
import {API_URL} from "../../constants";
import {EventSourcePolyfill} from 'event-source-polyfill';

const ConnectorContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

var EventSource = EventSourcePolyfill;

function ConnectorItems() {
    const auth = useAuth();
    const [connectors, setConnectors] = useState([]);

    const updateConnectors = () => {
        axios
            .get('/integrations/' + auth.user.tenant + '/connectors')
            .then((res) => {
                setConnectors(res.data.items);
            })
            .catch((err) => {

            });
    };

    useEffect(() => {
        const eventSource = new EventSource(API_URL + '/system/sse', {
            headers: {
                'Authorization': axios.defaults.headers.common['Authorization']
            }
        });
        eventSource.onmessage = (event) => {
            const eventData = event.data;
            if (eventData === 'ConnectorUpdated' || eventData === 'ConnectorDeleted') {
                updateConnectors();
            }
        };
        updateConnectors();
        return () => {
            eventSource.close();
        };
    }, []);

    return (<ConnectorContext.Provider value={updateConnectors}>
        <AddConnector/>
        <ConnectorContainer>
            {connectors.map((connector) => (<ConnectorItem connector={connector}/>))}
        </ConnectorContainer>
    </ConnectorContext.Provider>);
}

export default ConnectorItems;