import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import React, {useContext} from 'react';
import {useTable, usePagination, useSortBy} from 'react-table';
import styled from 'styled-components';
import {IoMdArrowDropup, IoMdArrowDropdown, IoMdTrash} from 'react-icons/io';
import axios from 'axios';
import timestampToDate from "../../functions/dateFormat";
import {useAuth} from "../../authContext";
import {FanContext} from "./FanContext";

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
`;

const StyledTh = styled.th`
  border: 1px solid var(--color-secondary);
  padding: 8px;
  text-align: left;
  color: white;
  background-color: var(--color-secondary);
`;

const StyledTd = styled.td`
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  font-size: 14px;
`;

const PageNavigation = styled.div`
  display: flex;
  align-items: center;
`;

const PageButton = styled.button`
  padding: 4px 8px;
  margin: 0 2px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const PageSizeSelect = styled.select`
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
`;

const DeleteIcon = styled(IoMdTrash)`
  cursor: pointer;
  color: rgba(9, 5, 5, 0.9);
  font-size: 15px;

  &:hover {
    color: #e31820;
  }
`;

const ResponsiveDataTable = ({data, onRowClick}) => {
    const auth = useAuth();
    const updateFans = useContext(FanContext);

    const columns = React.useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Birthday',
                accessor: 'birthDay',
                Cell: ({value}) => timestampToDate(value),
            },
            {
                Header: 'Age',
                accessor: 'age',
            },
            {
                Header: 'Address',
                accessor: 'address',
            },
            {
                Header: 'Zip Code',
                accessor: 'zipCode',
            },
            {
                Header: 'City',
                accessor: 'city',
            },
            {
                Header: 'Country',
                accessor: 'country',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Gender',
                accessor: 'gender',
            },
            {
                accessor: 'id',
                Cell: ({value}) => (
                    <ToasterContext.Consumer>
                        {({addToast}) => (
                            <DeleteIcon
                                onClick={async (e) => {
                                    e.preventDefault();
                                    await axios.delete(auth.user.tenant + '/fans/' + value).then(
                                        res => {
                                            if (res.status === 200) {
                                                addToast({text: "Fan deleted successfully", type: "success"});
                                                updateFans(0, true);
                                            } else {
                                                addToast({text: "Error deleting fan", type: "error"});
                                            }
                                        }
                                    ).catch(
                                        err => {
                                            addToast({text: "Error deleting fan", type: "error"});
                                        })
                                }}
                            />
                        )}
                    </ToasterContext.Consumer>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0, pageSize: 10},
        },
        useSortBy,
        usePagination
    );

    return (
        <TableContainer>
            <StyledTable {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                    {column.isSorted ? (
                        column.isSortedDesc ? (
                            <IoMdArrowDropdown/>
                        ) : (
                            <IoMdArrowDropup/>
                        )
                    ) : (
                        ''
                    )}
                  </span>
                            </StyledTh>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <StyledTd {...cell.getCellProps()}>{cell.render('Cell')}</StyledTd>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PageNavigation>
                    <PageButton onClick={() => gotoPage(0)} disabled={pageIndex === 0}>
                        First
                    </PageButton>
                    <PageButton onClick={() => gotoPage(pageIndex - 1)} disabled={pageIndex === 0}>
                        Previous
                    </PageButton>
                    <PageButton onClick={() => gotoPage(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>
                        Next
                    </PageButton>
                    <PageButton onClick={() => gotoPage(pageCount - 1)} disabled={pageIndex === pageCount - 1}>
                        Last
                    </PageButton>
                </PageNavigation>
                <div>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageCount}
                    </strong>{' '}
                </div>
                <PageSizeSelect
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((size) => (
                        <option key={size} value={size}>
                            Show {size}
                        </option>
                    ))}
                </PageSizeSelect>
            </PaginationContainer>
        </TableContainer>
    );
};

export default ResponsiveDataTable;
