import {Button, Field, Message} from "../../ui/core";

function BookmarkForm({
                          loading,
                          onSubmit,
                          info,
                          setInfo,
                          params,
                          setParams,
                          error
                      }) {

    const onChange = (e) => {
        setInfo({
            ...info,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <form onSubmit={onSubmit}>
            <Field labelText="Name" id="name">
                <input
                    type="text"
                    value={info.name}
                    onChange={onChange}
                    name="name"
                    id="name"
                />
            </Field>
            <Field labelText="Description" id="description">
                <input
                    type="text"
                    value={info.description}
                    onChange={onChange}
                    name="description"
                    id="description"
                />
            </Field>
            <Field labelText="Params" id="params">
                <input
                    type="text"
                    value={params}
                    onChange={(value) => setParams(value.value)}
                    name="params"
                    id="params"
                />
            </Field>
            <div>
                <Button loading={loading} type="submit">
                    Save
                </Button>
            </div>
            <Message text={error} type="error"/>
        </form>
    );
}

export default BookmarkForm;
