import {useState} from "react";
import Button from "../../ui/core/Button";
import AddBookmarkModal from "../../ui/AddBookmarkModal";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import BookmarkForm from "./BookmarkForm";
import axios from "axios";
import {useAuth} from "../../authContext";
import {DEFAULT_ANALYTICS_CONNECTOR} from "../../constants";

function AddBookmark({
                         params,
                         setParams
                     }) {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const infoObj = {
        name: "",
        description: "",
    }

    const data = {
        name: "",
        description: "",
        params: "",
        connectorId: DEFAULT_ANALYTICS_CONNECTOR,
    }

    const [info, setInfo] = useState({...infoObj});

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        data.name = info.name
        data.description = info.description
        data.params = params

        await axios.post('/analytics/' + auth.user.tenant + '/bookmarks', data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new bookmark", type: "success"});
                    setIsModal(false);
                } else {
                    setError("An error occurred while trying to save the bookmark");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the bookmark");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add Bookmark
                        </Button>
                    </div>
                    <AddBookmarkModal
                        title="Add new bookmark"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <BookmarkForm
                            {...{
                                loading,
                                info,
                                setInfo,
                                params,
                                setParams,
                                error
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddBookmarkModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddBookmark;
