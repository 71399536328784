import styled from "styled-components";
const FailedIcon = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 12px;
    height: 12px;
    border: 1px solid red;
    border-radius: 50%;
  }

  &::after {
    position: absolute;
    color: red;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "\\d7";
  }
`;

const FailedState = styled.span`
  color: red;
`;

function Failed() {
    return (
        <>
            <FailedIcon />
            <FailedState> Failed</FailedState>
        </>
    );
}

export default Failed;
