import Button from "../../ui/core/Button";
import { FiExternalLink } from 'react-icons/fi';

function Detach({url}) {
    const onClick = async (e) => {
        e.preventDefault();
        window.open(url, '_blank');
    };

    return (
        <Button onClick={onClick}>
            <FiExternalLink />
        </Button>
    );
}

export default Detach;
