import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import axios from "axios";
import {PostContext} from "./PostContext";
import {useAuth} from "../../authContext";
import {Message} from "../../ui/core";
import {FaTrash} from "react-icons/fa";
import {EventSourcePolyfill} from "event-source-polyfill";
import {API_URL} from "../../constants";

let EventSource = EventSourcePolyfill;

const PostItem = ({post, onDelete}) => {
    return (
        <PostCard>
            <PostInfo>
                <strong>{post.connectorId}</strong>
                <span>{post.data.text}</span>
                <span>{post.postConfigId}</span>
                <span>{post.link}</span>
                <span>{post.state}</span>
            </PostInfo>
            <DeleteButton onClick={() => onDelete(post.id)}>
                <FaTrash/>
            </DeleteButton>
        </PostCard>
    );
};

const PostList = ({
                      posts, onDelete
                  }) => {

    const updatePosts = useContext(PostContext);
    return (
        <Container>
            <PostContext.Provider value={updatePosts}>
                {posts.map((post) => (
                    <PostItem key={post.id} post={post} onDelete={onDelete}/>
                ))}
            </PostContext.Provider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const PostCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 80%;
    display: flex;
    justify-content: space-between;
`;

const PostInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;

    strong {
        font-size: 18px;
        margin-bottom: 4px;
    }

    span {
        color: #666;
    }
`;

const DeleteButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #ff4d4f;

    &:hover {
        color: #d63031;
    }
`;

const PostsManagement = () => {
    const auth = useAuth();
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const updatePosts = () => {
        setError(null)
        axios
            .get('/socialmedia/' + auth.user.tenant + '/posts')
            .then((res) => {
                setPosts(res.data.items);
            })
            .catch((err) => {
                setError('Error updating posts')
            });
    };

    useEffect(() => {
        const eventSource = new EventSource(API_URL + '/system/sse', {
            headers: {
                'Authorization': axios.defaults.headers.common['Authorization']
            }
        });
        eventSource.onmessage = (event) => {
            const eventData = event.data;
            if (eventData === 'PostUpdated' || eventData === 'PostDeleted') {
                updatePosts();
            }
        };
        updatePosts();
        return () => {
            eventSource.close();
        };
    }, []);

    const handleDelete = (postId) => {
        setSuccess(null)
        setError(null)
        axios
            .delete('/socialmedia/' + auth.user.tenant + '/posts/' + postId)
            .then((res) => {
                if (res.status === 200) {
                    setSuccess("post request successful")
                    updatePosts();
                } else {
                    setError('error deleting post')
                }

            })
            .catch((err) => {
                setError('error deleting post')
            });
    };


    return (<PostContext.Provider value={updatePosts}>
        <Message text={error} type="error"/>
        <Message text={success} type="success"/>
        <PostList posts={posts} onDelete={handleDelete}/>
    </PostContext.Provider>)
};

export default PostsManagement;
