import Page from "../ui/Page";
import SettingComponent from "../components/settings/Settings";

function Settings() {
    return (
        <Page title="Settings">
            <SettingComponent/>
        </Page>
    );
}

export default Settings;