import axios from "axios";
import React, {useState} from "react";
import {Button} from "../../../ui/core";
import {useAuth} from "../../../authContext";

const DeleteAccount = ({setError, setSuccess}) => {
    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth();

    const handleDeleteTenant = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true);
        axios.delete('/accounts/' + auth.user.tenant).then(
            res => {
                if (res.status === 200) {
                    setSuccess('Account deleted correctly');
                    setIsLoading(false);
                } else {
                    setError('Error deleting account')
                    setIsLoading(false);
                }
            },
            err => {
                setError('Error deleting account')
                setIsLoading(false);
            }
        );
    };

    return (
        <>
            <Button loading={isLoading} onClick={handleDeleteTenant}>Delete account</Button>
        </>
    )
}

export default DeleteAccount;