import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Connectors from "./pages/Connectors";
import "./styles.css";
import {ToasterProvider} from "./ui/toasterCtx/ToasterContext";
import {AuthProvider} from "./authContext";
import PrivateRoute from "./PrivateRoute";
import GlobalStyle from './GlobalStyle';
import Layout from './components/layout/Layout';
import {LoginForm} from "./pages/Login";
import Subscriptions from "./pages/Subscriptions";
import Settings from "./pages/Settings";
import Feedback from "./pages/Feedback";
import Analytics from "./pages/Analytics";
import Marketing from "./pages/Marketing";
import {RegisterForm} from "./pages/Register";
import {ResetPasswordForm} from "./pages/ResetPassword";
import {ForgotPasswordForm} from "./pages/ForgotPassword";
import Pricing from "./pages/Pricing";
import PrivateRoutePricing from "./PrivateRoutePricing";
import Fans from "./pages/Fans";
import Bookmarks from "./pages/Bookmarks";
import SocialMedia from "./pages/SocialMedia";
import PostConfigs from "./pages/PostConfigs";


function App() {
    return (
        <AuthProvider>
            <Router>
                <GlobalStyle/>
                <ToasterProvider>
                    <Layout>
                        <Switch>
                            <PrivateRoute path="/" exact>
                                <Redirect to="/home"/>
                            </PrivateRoute>
                            <PrivateRoute path="/home" exact>
                                <Home/>
                            </PrivateRoute>
                            <PrivateRoute path="/connectors" exact>
                                <Connectors/>
                            </PrivateRoute>
                            {/*<PrivateRoute path="/fans" exact>*/}
                            {/*    <Fans/>*/}
                            {/*</PrivateRoute>*/}
                            {/*<PrivateRoute path="/subscriptions" exact>*/}
                            {/*    <Subscriptions/>*/}
                            {/*</PrivateRoute>*/}
                            <PrivateRoute path="/marketing" exact>
                                <Marketing/>
                            </PrivateRoute>
                            <PrivateRoute path="/analytics" exact>
                                <Analytics/>
                            </PrivateRoute>
                            <PrivateRoute path="/bookmarks" exact>
                                <Bookmarks/>
                            </PrivateRoute>
                            <PrivateRoute path="/settings" exact>
                                <Settings/>
                            </PrivateRoute>
                            <PrivateRoutePricing path="/pricing" exact>
                                <Pricing/>
                            </PrivateRoutePricing>
                            <PrivateRoute path="/socialmedia" exact>
                                <SocialMedia/>
                            </PrivateRoute>
                            <PrivateRoute path="/post-configs" exact>
                                <PostConfigs/>
                            </PrivateRoute>
                            {/*<PrivateRoute path="/feedback" exact>*/}
                            {/*    <Feedback/>*/}
                            {/*</PrivateRoute>*/}
                            <Route path="/login">
                                <LoginForm/>
                            </Route>
                            <Route path="/register">
                                <RegisterForm/>
                            </Route>
                            <Route path="/forgotpassword">
                                <ForgotPasswordForm/>
                            </Route>
                            <Route path="/resetpassword/:token">
                                <ResetPasswordForm/>
                            </Route>
                        </Switch>
                    </Layout>
                </ToasterProvider>
            </Router>
        </AuthProvider>
    );
}

export default App;
