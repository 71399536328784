import React, {useState} from 'react';
import styled from 'styled-components';
import {useAuth} from "../../authContext";
import UserForm from "./profile/UserForm";
import TenantForm from "./tenant/TenantForm";
import UserManagement from "./users/UserManagement";

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#000' : '#999')};
  border-bottom: ${(props) => (props.selected ? '2px solid #000' : 'none')};
`;

const TabContent = styled.div`
  margin-top: 20px;
`;

const SettingsComponent = () => {
    const auth = useAuth();
    const [selectedTab, setSelectedTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setSelectedTab(tabIndex);
    };

    return (<div>
        <TabsContainer>
            <Tab selected={selectedTab === 1} onClick={() => handleTabClick(1)}>
                Profile
            </Tab>
            {auth.user.role === 'admin' && <Tab selected={selectedTab === 2} onClick={() => handleTabClick(2)}>
                Tenant
            </Tab>}
            {auth.user.role === 'admin' && <Tab selected={selectedTab === 3} onClick={() => handleTabClick(3)}>
                Users
            </Tab>}
        </TabsContainer>
        <TabContent>
            {selectedTab === 1 && <UserForm/>}
            {selectedTab === 2 && <TenantForm/>}
            {selectedTab === 3 && <UserManagement/>}
        </TabContent>
    </div>);
};

export default SettingsComponent;