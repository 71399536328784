import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import AddAudienceModal from "./AddAudienceModal.js";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import AudienceForm from "./AudienceForm";
import axios from "axios";
import {AudienceContext} from "./AudienceContext"
import {useAuth} from "../../authContext";

function AddAudience() {
    const auth = useAuth();
    const updateAudiences = useContext(AudienceContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const audienceObj = {
        name: "",
        description: "",
        bookmarkId: "",
        connectorId: "",
        permissionReminder: "",
        emailTypeOption: false,
        company: "",
        address1: "",
        city: "",
        country: "",
        state: "",
        zip: "",
        fromName: "",
        fromEmail: "",
        subject: "",
        language: "",
    }

    const config = {
        discriminator: "",
        name: "",
        permissionReminder: "",
        emailTypeOption: false,
        company: "",
        address1: "",
        city: "",
        country: "",
        state: "",
        zip: "",
        fromName: "",
        fromEmail: "",
        subject: "",
        language: "",
    }

    const data = {
        name: "",
        description: "",
        config: config,
    }

    const [provider, setProvider] = useState('')
    const [bookmarkId, setBookmarkId] = useState('')
    const [connectorId, setConnectorId] = useState('')
    const [audience, setAudience] = useState({...audienceObj});
    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        data.name = audience.name;
        data.description = audience.description;
        data.connectorId = connectorId;
        data.bookmarkId = bookmarkId;
        data.config.discriminator = provider;
        data.config.name = audience.name;
        data.config.permissionReminder = audience.permissionReminder;
        data.config.emailTypeOption = audience.emailTypeOption;
        data.config.company = audience.company;
        data.config.address1 = audience.address1;
        data.config.city = audience.city;
        data.config.country = audience.country;
        data.config.state = audience.state;
        data.config.zip = audience.zip;
        data.config.fromName = audience.fromName;
        data.config.fromEmail = audience.fromEmail;
        data.config.subject = audience.subject;
        data.config.language = audience.language;

        await axios.post('/marketing/' + auth.user.tenant + '/audiences', data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new audience", type: "success"});
                    setIsModal(false);
                    updateAudiences();
                } else {
                    setError("An error occurred while trying to save the audience");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the audience");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add audience
                        </Button>
                    </div>
                    <AddAudienceModal
                        title="Add new audience"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <AudienceForm
                            {...{
                                addToast: addToast,
                                loading,
                                audience: audience,
                                setAudience: setAudience,
                                provider: provider,
                                setProvider: setProvider,
                                connectorId: connectorId,
                                setConnectorId: setConnectorId,
                                bookmarkId: bookmarkId,
                                setBookmarkId: setBookmarkId,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddAudienceModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddAudience;
