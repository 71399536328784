import Page from "../ui/Page";
import BookmarksManagement from "../components/metabase/Bookmarks";

function Bookmarks() {
    return (
        <Page title="Bookmarks">
            <BookmarksManagement/>
        </Page>
    );
}

export default Bookmarks;