import {Button, Field, Message} from "../../ui/core";
import Select from "react-select";
import axios from "axios";
import {useAuth} from "../../authContext";
import {useEffect, useState} from "react";

let connectorMap = new Map();

function PostConfigForm({
                            loading,
                            onSubmit,
                            postConfig,
                            setPostConfig,
                            connectorId,
                            setConnectorId,
                            error,
                        }) {

    const auth = useAuth();
    const [connectors, setConnectors] = useState([]);
    useEffect(() => {
        axios.get('/integrations/' + auth.user.tenant + '/connectors?type=socialmedia').then(
            res => {
                let connectorsOptions = []
                res.data.items.map((connector) => {
                    connectorsOptions.push({
                        value: connector.id,
                        label: connector.name,
                    })
                    connectorMap.set(connector.id, connector.provider)
                })
                setConnectors(connectorsOptions)
            },
            err => {
                console.log(err)
            }
        )
    }, []);

    const onChange = (e) => {
        const {name, value} = e.target;
        if (name === 'hashtags') {
            setPostConfig({
                ...postConfig,
                hashtags: value.split(',').map(item => item.trim())
            });
        } else {
            setPostConfig({
                ...postConfig,
                [name]: value,
            });
        }
    }

    let config
    config = (
        <>
            <Field labelText="League Name" id="league-name-title">
                <input
                    type="text"
                    value={postConfig.leagueName}
                    onChange={onChange}
                    name="leagueName"
                    id="league-name-title"
                />
            </Field>
            <Field labelText="Hashtags" id="hashtags-title">
                <input
                    type="text"
                    value={Array.isArray(postConfig.hashtags) ? postConfig.hashtags.join(',') : ''}
                    onChange={onChange}
                    name="hashtags"
                    id="hashtags-title"
                />
            </Field>
        </>
    )

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Name" id="name-title">
                    <input
                        type="text"
                        value={postConfig.name}
                        onChange={onChange}
                        name="name"
                        id="name-title"
                    />
                </Field>
                <Field labelText="Description" id="description-title">
                    <input
                        type="text"
                        value={postConfig.description}
                        onChange={onChange}
                        name="description"
                        id="description-title"
                    />
                </Field>
                <Field labelText="Connector" id="connector">
                    <Select
                        options={connectors} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={
                            (value) => {
                                setConnectorId(value.value)
                            }
                        }
                        value={connectorId.value}
                        placeholder="Select connector"
                        name="connector"
                    />
                </Field>
                {config}
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
            </form>

        </>

    );
}

export default PostConfigForm;
