import Page from "../ui/Page";
import {useAuth} from "../authContext";
import ShortCuts from "../components/home/ShortCuts";

function Home() {
    const auth = useAuth();

    return (
        <Page title="Home">
            <h3>Welcome @{auth.user.username}!</h3>
            <ShortCuts/>
        </Page>
    );
}

export default Home;