import styled from 'styled-components';

const Content = styled.div`
    padding: 0 24px;
`;

function Page({title, children, ...rest}) {
    return (
        <div {...rest}>
            <Content>
                {children}
            </Content>
        </div>
    )
}

export default Page;