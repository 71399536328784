import {useAuth} from "../authContext";
import PricingTable from "../components/pricing/PricingTable";
import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

function Pricing() {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    useEffect(() => {
        if (auth.user.billing) history.replace(location.state ? location.state.from : '/');
    }, [])

    return (
        <PricingTable/>
    );
}

export default Pricing;