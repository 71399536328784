import styled from 'styled-components';
import Sync from "./Sync";
import Remove from "./Remove";
import State from "./State";
import Edit from "./Edit";
import timestampToDateTime from "../../functions/dateTimeFormat";

const surfaceMap = new Map();
surfaceMap.set('ARTIFICIAL_GRASS', 'Artificial grass');
surfaceMap.set('GRASS', 'Grass');
surfaceMap.set('HARD', 'Hard');
surfaceMap.set('CLAY', 'Clay');

const CardStyle = styled.div`
  background-color: white;
  min-width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  border: var(--color-secondary) solid 1px;
`

const TitleContainer = styled.div`
  font-size: 0.8rem;
  background-color: var(--color-secondary);
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  padding: 4px;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.8rem;
  padding: 2px;
  margin-top: 10px;
`

const ActionsContainer = styled.div`
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 7px;
`

function ConnectorItem({connector}) {
    return (
        <CardStyle>
            <TitleContainer>{connector.name}</TitleContainer>
            <HeaderContainer>
                <div>
                    Type: {connector.type}
                </div>
                <div>
                    Provider: {connector.provider}
                </div>
                <div>
                    State: <State state={connector.state}/>
                </div>
                <div>
                    Last Sync: {timestampToDateTime(connector.lastSync)}
                </div>
                <ActionsContainer>
                    <Sync connector={connector}></Sync>
                    <Edit connector={connector}></Edit>
                    <Remove connector={connector}></Remove>
                </ActionsContainer>
            </HeaderContainer>
        </CardStyle>
    )
}


export default ConnectorItem;
