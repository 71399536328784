import {Button, Field, Message} from "../../ui/core";
import Select from "react-select";
import axios from "axios";
import {useAuth} from "../../authContext";
import {useEffect, useState} from "react";

let connectorMap = new Map();

function AudienceForm({
                          addToast,
                          loading,
                          onSubmit,
                          audience,
                          setAudience,
                          provider,
                          setProvider,
                          connectorId,
                          setConnectorId,
                          bookmarkId,
                          setBookmarkId,
                          error,
                      }) {

    const auth = useAuth();
    const [connectors, setConnectors] = useState([]);
    const [bookmarks, setBookmarks] = useState([]);
    useEffect(() => {
        axios.get('/integrations/' + auth.user.tenant + '/connectors?type=marketing').then(
            res => {
                let connectorsOptions = []
                res.data.items.map((connector) => {
                    connectorsOptions.push({
                        value: connector.id,
                        label: connector.name,
                    })
                    connectorMap.set(connector.id, connector.provider)
                })
                setConnectors(connectorsOptions)
            },
            err => {
                console.log(err)
            }
        )

        axios.get('/analytics/' + auth.user.tenant + '/bookmarks').then(
            res => {
                let bookmarksOptions = []
                res.data.items.map((bookmark) => {
                    bookmarksOptions.push({
                        value: bookmark.id,
                        label: bookmark.name,
                    })
                })
                setBookmarks(bookmarksOptions)
            },
            err => {
                console.log(err)
            }
        )
    }, []);

    const onChange = (e) => {
        setAudience({
            ...audience,
            [e.target.name]: e.target.value,
        });
    }

    let config
    if (connectorMap.get(connectorId) === "MAILCHIMP") {
        config = (
            <>
                <Field labelText="Permission Reminder" id="permission-reminder-title">
                    <input
                        type="text"
                        value={audience.permissionReminder}
                        onChange={onChange}
                        name="permissionReminder"
                        id="permission-reminder-title"
                    />
                </Field>
                {/*<Field labelText="Email Type Option" id="email-type-option-title">
                    <input
                        type="checkbox"
                        value={audience.emailTypeOption}
                        onChange={onChange}
                        name="emailTypeOption"
                        id="email-type-option-title"
                    />
                </Field>*/}
                <Field labelText="Company" id="company-title">
                    <input
                        type="text"
                        value={audience.company}
                        onChange={onChange}
                        name="company"
                        id="company-title"
                    />
                </Field>
                <Field labelText="Address1" id="address1-title">
                    <input
                        type="text"
                        value={audience.address1}
                        onChange={onChange}
                        name="address1"
                        id="address1-title"
                    />
                </Field>
                <Field labelText="City" id="city-title">
                    <input
                        type="text"
                        value={audience.city}
                        onChange={onChange}
                        name="city"
                        id="city-title"
                    />
                </Field>
                <Field labelText="Country" id="country-title">
                    <input
                        type="text"
                        value={audience.country}
                        onChange={onChange}
                        name="country"
                        id="country-title"
                    />
                </Field>
                <Field labelText="State" id="state-title">
                    <input
                        type="text"
                        value={audience.state}
                        onChange={onChange}
                        name="state"
                        id="state-title"
                    />
                </Field>
                <Field labelText="Zip" id="zip-title">
                    <input
                        type="text"
                        value={audience.zip}
                        onChange={onChange}
                        name="zip"
                        id="zip-title"
                    />
                </Field>
                <Field labelText="From Name" id="from-name-title">
                    <input
                        type="text"
                        value={audience.fromName}
                        onChange={onChange}
                        name="fromName"
                        id="from-name-title"
                    />
                </Field>
                <Field labelText="From Email" id="from-email-title">
                    <input
                        type="text"
                        value={audience.fromEmail}
                        onChange={onChange}
                        name="fromEmail"
                        id="from-email-title"
                    />
                </Field>
                <Field labelText="Subject" id="subject-title">
                    <input
                        type="text"
                        value={audience.subject}
                        onChange={onChange}
                        name="subject"
                        id="subject-title"
                    />
                </Field>
                <Field labelText="Language" id="language-title">
                    <input
                        type="text"
                        value={audience.language}
                        onChange={onChange}
                        name="language"
                        id="language-title"
                    />
                </Field>
            </>
        )
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Name" id="name-title">
                    <input
                        type="text"
                        value={audience.name}
                        onChange={onChange}
                        name="name"
                        id="name-title"
                    />
                </Field>
                <Field labelText="Description" id="description-title">
                    <input
                        type="text"
                        value={audience.description}
                        onChange={onChange}
                        name="description"
                        id="description-title"
                    />
                </Field>
                <Field labelText="Bookmark" id="bookmark">
                    <Select
                        options={bookmarks} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={
                            (value) => {
                                setBookmarkId(value.value)
                            }
                        }
                        value={bookmarkId.value}
                        placeholder="Select bookmark"
                        name="bookmark"
                    />
                </Field>
                <Field labelText="Connector" id="connector">
                    <Select
                        options={connectors} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={
                            (value) => {
                                setConnectorId(value.value)
                                setProvider(connectorMap.get(value.value))
                            }
                        }
                        value={connectorId.value}
                        placeholder="Select connector"
                        name="connector"
                    />
                </Field>
                {config}
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
            </form>

        </>

    );
}

export default AudienceForm;
