import styled from "styled-components";

const SyncedIcon = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 12px;
    height: 12px;
    border: 1px solid #00b300;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 3px;
    left: 2px;
    width: 3px;
    height: 6px;
    border-width: 0px 1px 1px 0;
    border-style: solid;
    border-color: #00b300;
    transform: rotate(45deg);
  }
`;

const SyncedState = styled.span`
  color: #00b300;
`;

function Synced() {
    return (
        <>
            <SyncedIcon />
            <SyncedState> Synced</SyncedState>
        </>
    );
}

export default Synced;
