import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import EditForm from "./EditForm";
import axios from "axios";
import EditConnectorModal from "./EditConnectorModal";
import styled from "styled-components";
import {ConnectorContext} from "./ConnectorContext";
import {useState, useContext} from "react";
import {useAuth} from "../../authContext";
import {FaEdit} from "react-icons/fa";

const ActionButton = styled.button`
  & {
    background-color: orange;
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 20%;
    max-width: 80px;
  }

  &:hover {
    background-color: #c78c1c;
    opacity: 0.5;
  }
`


function Edit(props) {
    const updateConnectors = useContext(ConnectorContext);

    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);
    const auth = useAuth();

    const infoObj = {
        name: props.connector.name,
        description: props.connector.description,
        autoSync: props.connector.config.autoSync,
        interval: props.connector.config.interval,
    };

    const data = {
        name: "",
        description: "",
        config: {
            autoSync: false,
            interval: 0,
        },
    }

    const [info, setInfo] = useState({...infoObj});

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);

        data.name = info.name;
        data.description = info.description;
        data.config.autoSync = info.autoSync;
        data.config.interval = info.interval;

        await axios.put('/integrations/' + auth.user.tenant + '/connectors/' + props.connector.id, data,).then(
            res => {
                setLoading(false);
                if (res.status === 200) {
                    addToast({text: "Successfully connector updated", type: "success"});
                    setIsModal(false);
                    updateConnectors();
                } else {
                    setError("An error occurred while trying to update the connector");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to update the connector inf");
                setLoading(false);
            })
    };


    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={modalShow.bind(this, true)}>
                        <FaEdit/>
                    </ActionButton>
                    <EditConnectorModal
                        title="Edit connector"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <EditForm
                            {...{
                                loading,
                                info: info,
                                setInfo: setInfo,
                                error
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </EditConnectorModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default Edit;
