import Page from "../ui/Page";
import ConnectorItems from "../components/connectors/Connectors";

function Connectors() {
    return (
        <Page title="Connectors">
            <ConnectorItems/>
        </Page>
    );
}

export default Connectors;