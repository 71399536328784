function timestampToDateTime(date) {
    if (!date) {
        return ''; // Devolver cadena vacía si la fecha es null o undefined
    }
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getDate().toString().padStart(2, '0');
    const hours = originalDate.getHours().toString().padStart(2, '0');
    const mins = originalDate.getMinutes().toString().padStart(2, '0');
    const secs = originalDate.getSeconds().toString().padStart(2, '0');

    const dateTime = `${year}-${month}-${day} ${hours}:${mins}:${secs}`;
    return dateTime;
}

export default timestampToDateTime;