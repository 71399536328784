import Page from "../ui/Page";
import AudienceItems from "../components/marketing/Audiences";

function Marketing() {
    return (
        <Page title="Marketing">
            <AudienceItems/>
        </Page>
    );
}

export default Marketing;