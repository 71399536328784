import {Button, Field, Message} from "../../ui/core";


function EditForm({
                      loading,
                      onSubmit,
                      postConfig,
                      setPostConfig,
                      error,
                  }) {

    const onChange = (e) => {
        const {name, value} = e.target;
        if (name === 'hashtags') {
            setPostConfig({
                ...postConfig,
                hashtags: value.split(',').map(item => item.trim())
            });
        } else {
            setPostConfig({
                ...postConfig,
                [name]: value,
            });
        }
    }

    let config
    config = (
        <>
            <Field labelText="League Name" id="league-name-title">
                <input
                    type="text"
                    value={postConfig.leagueName}
                    onChange={onChange}
                    name="leagueName"
                    id="league-name-title"
                />
            </Field>
            <Field labelText="Hashtags" id="hashtags-title">
                <input
                    type="text"
                    value={Array.isArray(postConfig.hashtags) ? postConfig.hashtags.join(',') : ''}
                    onChange={onChange}
                    name="hashtags"
                    id="hashtags-title"
                />
            </Field>
        </>
    )

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Name" id="name-title">
                    <input
                        type="text"
                        value={postConfig.name}
                        onChange={onChange}
                        name="name"
                        id="name-title"
                    />
                </Field>
                <Field labelText="Description" id="description-title">
                    <input
                        type="text"
                        value={postConfig.description}
                        onChange={onChange}
                        name="description"
                        id="description-title"
                    />
                </Field>
                {config}
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
            </form>

        </>

    );
}

export default EditForm;
