import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const PStyled = styled.p`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  padding: 10px;
  border: 1px solid lightGray;
  background-color: #f0f0f0;
  border-radius: 6px;
  ${(props) =>
    props.type === "error" &&
    css`
      border-color: transparent;
      background-color: Tomato;
      color: white;
    `};
  ${(props) =>
    props.type === "success" &&
    css`
      border-color: transparent;
      background-color: Green;
      color: white;
    `};
`;

function Message({ text, type }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show the message when text is not null
    if (text) {
      setIsVisible(true);

      // Set a timeout to hide the message after 3 seconds
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      // Clean up the timeout when the component unmounts or when the message disappears
      return () => clearTimeout(timeoutId);
    }
  }, [text]);

  return (
      <PStyled type={type} isVisible={isVisible} className={type}>
        {text}
      </PStyled>
  );
}

export default Message;
