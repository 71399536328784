import {Button, Field, Message} from "../../ui/core";


function EditForm({
                      loading,
                      onSubmit,
                      info,
                      error,
                      setInfo,
                  }) {

    const onChange = (e) => {
        const {name, value, type, checked} = e.target;
        const newValue = type === 'checkbox' ? checked : type === 'number' ? parseInt(value, 10) : value;
        setInfo({
            ...info,
            [name]: newValue,
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <Field labelText="Name" id="info-name">
                <input
                    type="text"
                    value={info.name}
                    onChange={onChange}
                    name="name"
                    id="info-name"
                />
            </Field>
            <Field labelText="Description" id="info-description">
                <input
                    type="text"
                    value={info.description}
                    onChange={onChange}
                    name="description"
                    id="info-description"
                />
            </Field>
            <Field labelText="Auto Sync" id="info-auto-sync">
                <input
                    type="checkbox"
                    checked={info.autoSync}
                    onChange={onChange}
                    name="autoSync"
                    id="info-auto-sync"
                />
            </Field>
            <Field labelText="Interval" id="info-interval">
                <input
                    type="number"
                    value={info.interval}
                    onChange={onChange}
                    name="interval"
                    id="info-interval"
                />
            </Field>
            <div>
                <Button loading={loading} type="submit">
                    Save
                </Button>
            </div>
            <Message text={error} type="error"/>
        </form>
    );
}

export default EditForm;
