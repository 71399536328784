import React, {useEffect, useState} from "react";
import axios from "axios";
import {useAuth} from "../../authContext";
import {PostConfigContext} from "./PostConfigContext";
import styled from "styled-components";
import PostConfigItem from "./PostConfigItem";
import AddPostConfig from "./AddPostConfig";

const PostConfigContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

let connectorMap = new Map();
function PostConfigItems() {
    const auth = useAuth();
    const [postConfigs, setPostConfigs] = useState([]);
    const [connectors, setConnectors] = useState(null);
    const fetchPostConfigs = () => {
        axios.get('/socialmedia/' + auth.user.tenant + '/post-configs').then(
            res => {
                setPostConfigs(res.data.items)
            },
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        axios.get('/integrations/' + auth.user.tenant + '/connectors?type=socialmedia').then(
            res => {
                res.data.items.map((connector) => {
                    connectorMap.set(connector.id, connector.name)
                })
                setConnectors(connectorMap);
            },
            err => {
                console.log(err)
            }
        )
        fetchPostConfigs();
    }, [])

    return (
        <PostConfigContext.Provider value={fetchPostConfigs}>
            <AddPostConfig/>
            <PostConfigContainer>
                {postConfigs.map((postConfig) => (
                    <PostConfigItem postConfig={postConfig} connectors={connectors}/>
                ))}
            </PostConfigContainer>
        </PostConfigContext.Provider>
    );
}

export default PostConfigItems;