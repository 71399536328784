import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import EditForm from "./EditForm";
import axios from "axios";
import EditPostConfigModal from "./EditPostConfigModal";
import styled from "styled-components";
import {useState, useContext} from "react";
import {useAuth} from "../../authContext";
import {FaEdit} from "react-icons/fa";
import {PostConfigContext} from "./PostConfigContext";

const ActionButton = styled.button`
  & {
    background-color: orange;
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 20%;
    max-width: 80px;
  }

  &:hover {
    background-color: #c78c1c;
    opacity: 0.5;
  }
`


function Edit(props) {
    const fetchPostConfigs = useContext(PostConfigContext);

    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);
    const auth = useAuth();

    const postConfigObj = {
        name: props.postConfig.name,
        description: props.postConfig.description,
        connectorId: props.postConfig.connectorId,
        leagueName: props.postConfig.config.leagueName,
        hashtags: props.postConfig.config.hashtags,
    }

    const config = {
        leagueName: "",
        hashtags: [],
    }

    const data = {
        name: "",
        description: "",
        connectorId: "",
        config: config,
    }

    const [postConfig, setPostConfig] = useState({...postConfigObj});

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        data.name = postConfig.name;
        data.description = postConfig.description;
        data.config.leagueName = postConfig.leagueName;
        data.config.hashtags = postConfig.hashtags;

        await axios.put('/socialmedia/' + auth.user.tenant + '/post-configs/' + props.postConfig.id, data,).then(
            res => {
                setLoading(false);
                if (res.status === 200) {
                    addToast({text: "Successfully created a new post config", type: "success"});
                    setIsModal(false);
                    fetchPostConfigs();
                } else {
                    setError("An error occurred while trying to save the post config");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the post config");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={modalShow.bind(this, true)}>
                        <FaEdit/>
                    </ActionButton>
                    <EditPostConfigModal
                        title="Edit Post Config"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <EditForm
                            {...{
                                loading,
                                postConfig: postConfig,
                                setPostConfig: setPostConfig,
                                error
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </EditPostConfigModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default Edit;
