import axios from "axios";
import Cookies from 'universal-cookie'
import {API_URL} from "../constants";

const cookies = new Cookies();
axios.defaults.baseURL = API_URL

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;
        const response = await axios.post('/accounts/refresh', {},
            {headers: {'Authorization': 'Bearer ' + cookies.get('refreshToken')}})

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
            cookies.set('accessToken', response.data.accessToken, {
                path: '/',
                expires: new Date(Date.now() + 2592000)
            });
            return axios(error.config);
        }
    }
    refresh = false;
    return error;
});

