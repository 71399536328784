import React from "react";
import Synced from "../state/Synced";
import Failed from "../state/Failed";
import Syncing from "../state/Syncing";
import Removing from "../state/Removing";

const stateMap = {
    SYNCED: <Synced/>,
    FAILED: <Failed/>,
    SYNCING: <Syncing/>,
    REMOVING: <Removing/>,
    CREATED: <span>Created</span>,
};

function State({state}) {
    const displayValue = stateMap[state] || <span>Unknown</span>;
    return displayValue;
}

export default State;