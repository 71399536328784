import Page from "../ui/Page";

function Feedback() {
    return (
        <Page title="Feedback">
            <h3>Page under construction</h3>
        </Page>
    );
}

export default Feedback;