import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button, Message } from "../../../ui/core";
import { useAuth } from "../../../authContext";
import Billing from "./Billing";
import DeleteAccount from "./DeleteAccount";
import Select from "react-select";

const FormContainer = styled.div`
    max-width: 400px;
    margin: auto;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-size: 1.05rem;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
`;

const TenantForm = () => {
    const auth = useAuth();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        config: {
            teamIds: [],
            country: "",
        }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const fetchCountries = () => {
        axios.get('system/countries').then(
            res => {
                let options = [];
                res.data.countries.forEach(element => options.push({ value: element.name, label: element.flag + ' ' + element.name }));
                setCountries(options);
            },
            err => {
                console.log(err);
            }
        );
    };

    const fetchTenant = () => {
        axios.get('/accounts/' + auth.user.tenant).then(
            res => {
                setFormData({
                    name: res.data.name,
                    description: res.data.description,
                    config: {
                        teamIds: res.data.config.teamIds || [],
                        country: res.data.config.country.name || ""
                    }
                });
                setIsLoading(false);
            },
            err => {
                setIsLoading(false);
            }
        );
    };

    useEffect(() => {
        fetchTenant();
        fetchCountries();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'teamIds') {
            setFormData({
                ...formData,
                config: {
                    ...formData.config,
                    teamIds: value.split(',').map(item => item.trim())
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleCountryChange = (selectedOption) => {
        setFormData({
            ...formData,
            config: {
                ...formData.config,
                country: selectedOption.value
            }
        });
    };

    const handleSaveClick = async () => {
        setError(null);
        setSuccess(null);
        setIsSaving(true);
        try {
            const response = await axios.put('/accounts/' + auth.user.tenant, formData);
            if (response.status === 200) {
                setSuccess("Tenant updated correctly");
                setError(null);
                setIsSaving(false);
            } else {
                setError("Error updating tenant");
                setSuccess(null);
                setIsSaving(false);
            }
        } catch (error) {
            setError("Error updating tenant");
            setSuccess(null);
            setIsSaving(false);
        }
    };

    if (isLoading) {
        return <div></div>;
    }

    return (
        <>
            <FormContainer>
                <FormGroup>
                    <Label>Name:</Label>
                    <Input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Name"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Description:</Label>
                    <Input
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Description"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Teams:</Label>
                    <Input
                        type="text"
                        name="teamIds"
                        value={Array.isArray(formData.config.teamIds) ? formData.config.teamIds.join(',') : ''}
                        onChange={handleInputChange}
                        placeholder="Teams"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Country:</Label>
                    <Select
                        name="country"
                        value={countries.find(option => option.value === formData.config.country)}
                        onChange={handleCountryChange}
                        options={countries}
                        placeholder="Select Country"
                    />
                </FormGroup>
                <Button loading={isSaving} onClick={handleSaveClick}>Save</Button>
                <Billing setError={setError} setSuccess={setSuccess} />
                <DeleteAccount setError={setError} setSuccess={setSuccess} />
            </FormContainer>
            <Message text={error} type="error" />
            <Message text={success} type="success" />
        </>
    );
};

export default TenantForm;
