import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {Button, Message} from "../../../ui/core";

const FormContainer = styled.div`
  max-width: 400px;
  margin: auto;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 1.05rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
`;

const UserForm = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [formData, setFormData] = useState({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const updateSetForm = () => {
        axios
            .get('/accounts/me')
            .then((res) => {
                setFormData({
                    username: res.data.username,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        updateSetForm();
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSaveClick = async () => {
        setError(null);
        setSuccess(null);
        setIsSaving(true)
        try {
            const response = await axios.put('/accounts/me', formData);
            if (response.status === 200) {
                setSuccess("User updated correctly");
                setIsSaving(false);
            } else {
                setError("Error updating user");
                setIsSaving(false);
            }
        } catch (error) {
            setError("Error updating user");
            setIsSaving(false);
        }
    };

    if (isLoading) {
        return <div></div>;
    }

    return (
        <>
            <FormContainer>
                <FormGroup>
                    <Label>Username:</Label>
                    <Input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        placeholder="Username"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>First Name:</Label>
                    <Input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        placeholder="First Name"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Last Name:</Label>
                    <Input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        placeholder="Last Name"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Email:</Label>
                    <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                    />
                </FormGroup>
                <Button loading={isSaving} type="submit" onClick={handleSaveClick}>Save</Button>
            </FormContainer>
            <Message text={error} type="error"/>
            <Message text={success} type="success"/>
        </>

    );
}

export default UserForm;
