import styled from "styled-components";

const LogoStyle = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding-top: 5%;
  padding-bottom: 5%;
`

function Logo() {
    return (
        <a href={'/'}>
            <LogoStyle src={'logo.png'}/>
        </a>)
        ;
}

export default Logo;
