import {useContext, useState} from "react";
import Button from "../../../ui/core/Button";
import AddUserModal from "./AddUserModal.js";
import {ToasterContext} from "../../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import {UserContext} from "./UserContext"
import {useAuth} from "../../../authContext";
import AddUserForm from "./AddUserForm";

function AddUser() {
    const auth = useAuth();
    const updateUsers = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const userObj = {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
    }

    const data = {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
    }

    const [user, setUser] = useState({...userObj});
    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        data.username = user.username;
        data.email = user.email;
        data.firstName = user.firstName;
        data.lastName = user.lastName;
        data.password = user.password;

        await axios.post('accounts/' + auth.user.tenant + "/users", data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new user", type: "success"});
                    setIsModal(false);
                    updateUsers();
                } else {
                    setError("Error 1");
                }
            }
        ).catch(
            err => {
                setError("Error 2");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add user
                        </Button>
                    </div>
                    <AddUserModal
                        title="Add new user"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <AddUserForm
                            {...{
                                loading,
                                user: user,
                                setUser: setUser,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddUserModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddUser;
