import Page from "../ui/Page";
import UploadMembershipCSV from "../components/subscriptions/UploadMembershipCSV";
import UploadSupporterCSV from "../components/subscriptions/UploadSupporterCSV";

function Subscriptions() {
    return (
        <Page title="Subscriptions">
            <UploadMembershipCSV/>
            <UploadSupporterCSV/>
        </Page>
    );
}

export default Subscriptions;