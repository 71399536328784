import React from "react";
import { NavLink } from "react-router-dom";

const buttonStyle = {
    display: "inline-flex",
    fontSize: "11px",
    paddingLeft: "10px",
    paddingRight: "10px",
    height: "30px",
    lineHeight: "30px",
    textAlign: "center",
    textDecoration: "none",
    backgroundColor: "#042a38",
    color: "#ffffff",
    borderRadius: "4px",
    cursor: "pointer",
};

const hoverStyle = {
    backgroundColor: "#2980b9",
    borderColor: "#2980b9",
};

function GoBookmarks() {
    return (
        <NavLink
            to="/bookmarks"
            activeClassName="active"
            style={buttonStyle}
            activeStyle={hoverStyle}
        >
            Go to Bookmarks
        </NavLink>
    );
}

export default GoBookmarks;