import styled from 'styled-components';
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../authContext";
import {Message} from "../ui/core";

const LogoStyle = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding-top: 10%;
`

const LoginFormStyle = styled.div`
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 70%;
  margin: 40px auto;
  background-color: #FFFFFF;
  border-radius: 25px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
`

const RowInput = styled.input`
  & {
    width: 80%;
    box-sizing: border-box;
    border: none;
    font-size: 0.95rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px rgba(9, 8, 8, 0.2);
    transition: box-shadow 0.2s ease-in;
    -webkit-appearance: none;
    border-radius: 0px;
  }

  &:focus {
    box-shadow: inset 0px -3px 0px 0px var(--color-primary);
    outline: none;
  }

  &::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.25s ease-out;
    color: rgba(9, 5, 5, 0.34);
  }

  &:hover::-webkit-input-placeholder,
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }
`

const RowLabel = styled.label`
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: #555;
  font-size: 1rem;
`

const RowButton = styled.button`
  & {
    border-radius: 25px;
    width: 80%;
    height: 40px;
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
    background: linear-gradient(90deg, var(--color-secondary) 0%, var(--color-secondary-light) 100%);
    border: 2px solid;
    border-color: var(--color-primary);
    cursor: pointer;
    transition: opacity 0.25s ease-out;
  }

  &:hover {
    opacity: 0.8;
  }
`

const AlternativeLogin = styled.div`
  text-align: center;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  font-size: 1rem;
`

const SignUpStyle = styled.div`
  text-align: center;
  padding-top: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
`

const ForgotPasswordStyle = styled.div`
  text-align: right;
  margin-top: 15px;
  font-size: 0.8rem;
  width: 90%;
`

const ForgotPassLinkStyle = styled.a`
  color: #555;
`

const IconGroup = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`

const IconGroupFacebook = styled.a`
  margin: 0 0.6rem;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/f/fb/Facebook_icon_2013.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  width: 3rem;
  height: 3rem;
`

const IconGroupGoogle = styled.a`
  margin: 0 0.6rem;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  width: 3rem;
  height: 3rem;
`

const IconGroupTwitter = styled.a`
  margin: 0 0.6rem;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/4/4f/Twitter-logo.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  width: 3rem;
  height: 3rem;
`


export const LoginForm = () => {
    const history = useHistory();
    const location = useLocation();
    const [creds, setCreds] = useState({
        username: "", password: "",
    });
    const [error, setError] = useState(null);
    const auth = useAuth();

    useEffect(() => {
        if (auth.user) history.replace(location.state ? location.state.from : '/');
    }, [auth.user])

    const onChange = (e) => {
        setCreds((prevCreds) => ({
            ...prevCreds, [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null)
        try {
            await auth.signIn(creds.username, creds.password);
        } catch (e) {
            setError("Error with user and password");
        }
    }

    return (
        <>
            <Message text={error} type="error"/>
            <LoginFormStyle>
                <form onSubmit={handleSubmit}>
                    <FormHeader/>
                    <Form creds={creds} onChange={onChange}/>
                    {/*<OtherMethods/>*/}
                    <SignUp/>
                </form>
            </LoginFormStyle>
        </>)
}

const FormHeader = () => (
    <a href={'/'}>
        <LogoStyle src={'logo.png'}/>
    </a>);


const Form = props => (<div>
    <FormInput value={props.creds.username} onChange={props.onChange} description="Username"
               placeholder="Enter your username"
               type="text" name="username"/>
    <FormInput value={props.creds.password} onChange={props.onChange} description="Password"
               placeholder="Enter your password"
               type="password" name="password"/>
    <ForgotPassword/>
    <FormButton title="Login"/>
</div>);

const FormButton = props => (<Row>
    <RowButton type="submit">{props.title}</RowButton>
</Row>);

const FormInput = props => (<Row>
    <RowLabel>{props.description}</RowLabel>
    <RowInput type={props.type}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
              name={props.name}
              id={props.name}
    />
</Row>);

const OtherMethods = () => (<AlternativeLogin>
    <label>Or sign in with:</label>
    <IconGroup>
        <Facebook/>
        <Twitter/>
        <Google/>
    </IconGroup>
</AlternativeLogin>);

const SignUp = () => (<SignUpStyle>
    <label>New in ClubTrack? </label>
    <a href={'/register'}>Sign Up</a>
</SignUpStyle>);

const ForgotPassword = () => (<ForgotPasswordStyle>
    <ForgotPassLinkStyle href={'/forgotpassword'}>Forgot password?</ForgotPassLinkStyle>
</ForgotPasswordStyle>);


const Facebook = () => (<IconGroupFacebook href="#"></IconGroupFacebook>);

const Twitter = () => (<IconGroupTwitter href="#"></IconGroupTwitter>);

const Google = () => (<IconGroupGoogle href="#"></IconGroupGoogle>);
